/* @font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica.dfont);
} */
@font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica.ttf);
}
/* @font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/Helvetica.ttc);
} */
/* @font-face {
  font-family: Helvetica;
  src: url(./assets/fonts/HelveticaNeue.ttc);
} */

:root {
  --section_a_image_text_margin_top: 25px;
}
body {
  margin: 0;
  font-family:'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
background-color: #ddcfcb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
body {
  margin: 0;
  padding: 0;
}

.logo {
  width: 200px;
}

/* #region Section Foreside */

.section-foreside{
  overflow-y: auto;
}
.section-foreside .image-container{
  height: 33.33333333%;
}
.section-foreside .image-container .image-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-family: Helvetica;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  letter-spacing: 0.2px;
}
.section-foreside .image-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

/* #endregion */

/* #region Section A */

.section-a {
  overflow-y: auto;
}
.section-a .category-container {
  height: 70%;
  min-height: 250px;
  max-height: 500px;
}
.section-a img.sticker {
  position: absolute;
  top: -48px;
  right: 10%;
  width: 130px;
  height: auto;
}
.section-a .image-container{
  height: calc(100% - 20px - var(--section_a_image_text_margin_top));
}
.section-a .image-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}
.section-a .image-text{
  margin-top: var(--section_a_image_text_margin_top);
  color: #533214;
}
.button{
  background-color: #533214;
  padding: 10px 35px;
  margin-bottom: 5px;
  color: white;
  border: none;
  border-radius: 12px;
}

/* #endregion */

/* #region Section B */

.section-b {
  overflow-y: auto;
}
.section-b .image-container{
  height: 35vh;
  min-height: 210px;
}
.section-b .image-container .sticker{
  position: absolute;
  z-index: 2;
  top: -48px;
  width: 130px;
  height: auto;
}
.section-b .image-container .sticker-one{
  left: 10%;
}
.section-b .image-container .sticker-two{
  right: 10%;
}
.section-b .image-container .image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section-b .image-container .image-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-family: Helvetica;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  letter-spacing: 0.2px;
}
.section-b .about{
  margin-inline: auto;
  max-width: 1300px;
  text-align: justify;
}

/* #endregion */

/* #region Order form */

#orderForm .modal-header {
  background-image: url("./assets/images/IMG5.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
#orderForm .modal-header:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1d0e0075;
  z-index: 1;
}
#orderForm .modal-title {
  position: relative;
  z-index: 2;
}
#orderForm .close-btn{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  z-index: 2;
  font-size: 25px;
  cursor: pointer;
}
#orderForm input{
  padding: 10px;
  border: none;
  margin-bottom: 5px;
}
#orderForm input::placeholder, #orderForm textarea::placeholder {
  font-style: italic;
}
textarea{
  border: none;
  margin-bottom: 12px;
  padding: 5px 10px;
}

/* #endregion */

footer{
  background-color: #887e76;
}
.social-icons-list{
  list-style: none;
  gap: 1rem;
  margin-inline: 30px;
}
.social-icon {
  height: 35px;
}

@media (max-width: 768px) {
  .section-a .category-container {
    height: 55%;
  }
}

@media (max-width: 576px) {
  .section-a img.sticker {
    top: -20px;
  }

  .section-b .image-container{
    height: 250px;
  }

  .section-b .image-container .sticker-two{
    top: 15px;
    width: 100px;
  }
}

@media (max-width: 330px) {
  .logo {
    width: 160px;
  }
}